body {
    background: #ffffff;
}
.search_table {
      width: 100%;
      border: 1px solid #ccc;
      margin: 0 auto;
      margin-top: 20px;
      height: 50px;
      font-size: 15px;
      outline: none;
      padding-left: 10px;
    margin-bottom: 20px;
}
 .table_  {
   overflow: auto;
   width: 100%;
   border-collapse: collapse;
   box-shadow: 0px 10px 10px #b0afaf22;
   -moz-border-radius: 60px;
   -webkit-border-radius: 60px;
   border-radius: 60px;
   -ms-border-radius: 60px;
   -o-border-radius: 60px;
}

 .table_  thead {
    background: #F9FAFB;
    height: 50px;
    text-align: left;
}
 .table_  thead  tr {
    padding: 10px;
 }
 .table_  thead  tr th {
    padding: 10px;
    color: #A2A6B0;
    font-weight: 400;
    font-size: 15px;
 }
 .table_ tbody .table_student_info {
    display: flex;
    align-items: center;
    height: 50px;
 }

 .table_ tbody tr {
    background: #fff;
    height: 80px;
    text-align: left;
 }
 .table_ tbody tr:hover {
    background: #f5f5f5;
    cursor: pointer;
 }
 .table_ tbody tr td {
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
 }
 .table_ tbody .table_student_info img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    object-fit: cover;
    margin-right: 20px;
}
.table_ tbody .table_student_info h2 {
      font-size: 14px;
      font-weight: 600;
      color: #000;
}
.table_ tbody .table_student_info p {
   font-size: 14px;
   font-weight: 300;
   color: grey;
}
.action_buttons {
   display: flex;
   align-items: center;

}
.btn_show {
      background: #00a65a;
      color: #fff;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;
}
.btn_delete {
      background: #dd4b39;
      color: #fff;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
}
.title_in_pages {
      font-size: 40px;
      font-weight: 600;
      color: #000;
}

.desc_in_pages {
      font-size: 20px;
      font-weight: 300;
      color: rgb(135, 130, 130);
      margin-bottom: 30px;
}


/* profile student */

.bg_profile_student {
   height: 40vh;
   background: rgb(255,218,92);
   background: linear-gradient(90deg, rgba(255,218,92,1) 0%, rgba(255,245,238,1) 100%);
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.student_profile_header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-left: 100px;
   margin-right: 100px;
}

.student_profile_header .student_img_profile {
   width: 220px;
   height: 220px;
   border-radius: 50%;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
   margin-top: -100px;
   box-shadow: 1px -1px 26px 2px rgba(238, 195, 195, 0.36);
   -webkit-box-shadow: 1px -1px 26px 2px rgba(255, 215, 215, 0.36);
   -moz-box-shadow: 1px -1px 26px 2px rgba(255, 221, 221, 0.36);
   border: 5px solid white;
}

.student_profile_header .profile_student_data_right {
   display: flex;
   align-items: center;
}

.student_profile_header .profile_student_data_right  .send_msg_btn {
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: #FDFDFD;
   height: 50px;
   width: 170px;
   border: 1px solid lightgray;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   padding: 0 15px;
   font-size: 14px;
   margin-right: 20px;
}


.student_profile_header .profile_student_data_right  .edit_student_data_btn {
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: rgba(0, 0, 255, 0.636);
   height: 50px;
   width: 100px;
   border: 1px solid transparent;
   border-radius: 5px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   padding: 0 15px;
   font-size: 14px;
   color: white;
}

.student_profile_body {
   margin-left: 110px;
   margin-right: 110px;
   margin-top: 20px;
}

.student_profile_body .profile_student_name {
   font-size: 24px;
   font-weight: bold;
}

.point_ {
   margin-right: 10px;
   margin-left: 10px;
}

.student_profile_data {
   color: rgb(65, 59, 72);
   font-weight: 400;
   font-size: 15px;
   margin-top: 10px;
}

.the_hearozintal {
   margin-left: 110px;
   margin-right: 110px;
   margin-top: 20px;
   margin-bottom: 20px;
}

.profile_body_details {
   margin-left: 110px;
   margin-right: 110px;
}

.profile_body_details_title {
   font-size: 20px;
   font-weight: bold;

}
.student_info_single_box {
   margin-top: 20px;
}
._names_of_info {
   color: #A2A6B0;
}
.student_cin_img_profile {
   width: 400px;
   object-fit: contain;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.student_answers_name {
   color: black;
   margin-left: 20px;
}

.video_watch_section_card_video {
   display: flex;
   flex-direction: row;
   width: 100%;
   height: 100%;
   margin-top: 30px;
   cursor: pointer;
   margin-left: 110px;
   margin-right: 110px;
}
.video_watch_section_card_video:hover {
   background-color: #f6ebfc3c;
   margin-left: 110px;
   margin-right: 110px;
}
.wtch_boxs_all {
   padding: 50px;

}
.wtch_boxs_all  h1 {
   font-size: 30px;
   color: orange;
   font-weight: bold;
   user-select: none;
}

 .video_watch_section_card_video img {
   width: 350px;
   height: 250px;
   object-fit: cover;
   margin-right: 30px;
}


.video_watch_section_card_video h2 {
   font-size: 30px;
   color: rgb(21, 22, 35);
   font-weight: bold;
   user-select: none;
}

.video_watch_section_card_video p {
   font-size: 20px;
   color: rgb(105, 91, 91);
   user-select: none;
   max-width: 500px;
}
.video_watch_section_card_video .video_watch_section_card_video_button {
  color: rgb(255, 4, 4);
  display: flex;
  align-items: center;
  flex : 1;
  width: 255px;
  margin-top: 15px;
}

.modal_parrent {
   z-index: 999;
   position: absolute;
   width: 100%;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.379);
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   overflow-y: scroll;
}


.stop_scrol {
   overflow-y: hidden;
}
.forn_edit_student_data {
   background-color: white;
   width: 600px;
   padding: 20px;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}
.forn_edit_student_data  input {
   outline: none;
}

.forn_edit_student_data  input:focus {
   border: none;
}

.close_modal_btn {
   position: absolute;
   top: 0;
   right : 0;
   padding: 20px;
   color: white;

}

.close_modal_font {
   font-size: 50px;
}

.profile_update_input_upload {
   position :  absolute;
   top: 0;
   width : 90%;
   background-color: rgba(0, 0, 0, 0.318);
   height: 100%;
   display: flex;
   justify-content: center;
   opacity: 0;
   border-radius: 20px;
}
.profile_update_input_upload > img {
   position: absolute;
   top: 34%;
   left: 34%;
}
.profile_update_input_upload > input {
   opacity: 0;
}
.profile_update_input_upload:hover  {
   opacity: 1;
   transition: all 0.5s;
}

.table_container  input {
   width: 100%;
}
.table_container {
   position: relative;
   overflow-x: scroll;
   overflow-y: scroll;
   max-height: 900px;
}