.searc_hbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  background-color:  white;
  padding-left: 20px;
}

.searc_hbox input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border : none;
}

.searc_hbox .searchicon {
  color: #777;
  font-size: 20px;
}


/* hide the input design and show new design */
.input-profile {
  width: 100%;
  opacity:  0;
  position: absolute;
  height: 200px;
  border: 1px solid #ccc;
}

.profile-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}